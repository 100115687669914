@use '../../styles/base.scss' as *;

.section {
    width: 518px;
    @include mobile {
        width: 375px;
        margin-bottom: 50px;
    }
    &__content {
      display: flex;
      justify-content: center;
      gap: 43px;
      @include laptop {
        gap: 60px;
      }
      @include mobile {
        justify-content: flex-start;
      }
    
       &__element {     
        position: relative;
        &__number {
          display: none;
          @include mobile {
            display: block;
            position: absolute;
            font: 64px 'Baskerville';
            font-style: italic;
            left: 104px;
          }
        } 
        &__image {
          width: 518px;
          height: 345px;
          object-fit: cover;
          @include laptop {
            width: 383px;
            height: 255px;
          }
          @include tablet {
            width: 500px;
            height: 300px;
          }
          @include mobile {
            width: 224px;
            height: 149px;
            margin-left: 130px;
          }
     
      }
        &__heading {
          margin-top: 20px;
          font: normal 14px 'Baslerville';
          letter-spacing: 1.12px;
          margin-left: 20px;

          @include laptop {
            margin-left: 0;
          }
          @include mobile {
            margin-left: 20px;
          }
        }
        &__paragraph {
          margin-top: 19px;
          @include bodyText;
          letter-spacing: 0.28px;
          line-height: 22px;
          @include mobile {
            margin-left: 20px;
            line-height: 16px;
          }
        }
      }
    }
    span {
      display: none;
      @include tablet {
        display: inline;
      }
    }
}



 

