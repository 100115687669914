@use '../../styles/base.scss' as *;

.section {
    width: 1194px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include laptop {
      width: 1240px;
    }
    @include tablet {
      width: 768px;
    }
    @include mobile {
      width: 100%;
    }
    &__content {
      display: flex;
      justify-content: center;
      gap: 43px;
      @include laptop {
        gap: 60px;
      }
      @include tablet {
        gap: 36px;
      }
      @include mobile {
        flex-direction: column;
        margin: 0 auto;
        width: 344px;
      }
       &__element {      
        &__image {
          width: 576px;
          height: 384px;
          object-fit: cover;
          @include tablet {
            width: 346px;
            height: 231px;
          }
          @include mobile {
            width: 344px;
            height: 287px;
          }
      }
        &__heading {
          margin-top: 20px;
          font: normal 14px 'Baslerville';
          letter-spacing: 1.12px;
          margin-left: 20px;
          @include laptop {
            margin-left: 0;
          }
          @include tablet {
            margin-left: 20px;
          }
          @include mobile {
            margin-left: 10px;
          }
        }
        &__paragraph {
          margin-top: 19px;
          width: 490px;
          height: 195px;
          margin-left: 20px;
          @include bodyText;
          letter-spacing: 0.28px;
          line-height: 22px;
          @include laptop {
            margin-left: 0;
          }
          @include tablet {
            width: 291px;
            height: 200px;
            margin-left: 20px;
          }
          @include mobile {
            width: 322px;
            margin-left: 10px;
            height: fit-content;
            font-size: 11px !important;
            line-height: 20px;
            letter-spacing: 0.11px;
          }
        }
        &__discoverMore {  
          color: #1a1a1a;
          margin-top: 20px;
          font: bold 8.25px 'Alright-Sans';
          letter-spacing: 1.65px;
          width: fit-content;
          margin-left: 20px;
          @include laptop {
            margin-left: 0;
          }
          @include tablet {
            margin-top: 0px;
            margin-left: 20px;
          }
          @include mobile {
            font-family: 'Arial';
            margin-top: 30px;
            margin-left: 10px;
          }
        }
        &__discoverMore::after {
          display:block;
          content: '';
          border-bottom: solid 1.5px #1a1a1a;  
          transform: scaleX(0);  
          transition: transform 250ms ease-in-out;
          transform-origin:  0% 50%;
        }
        &__discoverMore:hover:after { 
          transform: scaleX(1);
        }
      }
    }
}

#fuelConsuption {
  @include tablet {
    display: none;
  }
  @include mobile {
    display: block;
  }
}

 

