@use '../../styles/base.scss' as *;

.section {
  margin: 0 auto;
  width: 1920px;
  background-color: #eeefef;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 66px 366px;
  @include laptop {
    width: 1440px;
    padding: 66px 100px;
    gap: 82px;
  }
  @include tablet {
    width: 768px;
    padding: 34px 20px;
    gap: 12px;
  }
  @include mobile {
    width: 375px;
    height: 480px;
    margin-top: 15rem;
    position: relative;
  }
  &__content {
    width: 360px;
    height: 514px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    @include tablet {
      width: 300px;
      height: 400px;
    }
    @include mobile {
      border: none;
    }
    &__heading {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 30px;
      @include tablet {
        margin-top: 21px;
      }
      @include mobile {
        margin-top: 50px;
      }
      h4 {
        @include subheading;
        letter-spacing: 1.8px;
        line-height: 20px;
      }
    }
    &__text {
      margin: 0 auto;
      @include bodyText; 
      letter-spacing: 0.11px;
      line-height: 22px;
      text-align: center;
      @include tablet {
        margin-top: 0;
      }
    }
    .buttonWrapper {
      width: fit-content;
      margin: 0 auto;
      margin-top: 20px;
      @include tablet {
        margin-top: 0px;
      }
      @include mobile {
        margin-top: 10px;
      }
    }
    button {
      background: #1a1a1a00 0% 0% no-repeat padding-box;
      border: 1px solid #000000;
      color: #1a1a1a;
      width: 180px;
      height: 46px;
      font: bold 8.25px 'Alright-Sans';
      letter-spacing: 1.65px;
      text-transform: uppercase;
      @include mobile {
        font-family: 'Arial';
      }
    }
    button:hover{
      background-color: #1a1a1a;
      color: white;
    }
  }
  &__image {
    overflow: hidden;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    background-image: url('../../images/Dinning.jpg');
    width: 786px;
    height: 514px;
    @include tablet {
      width: 416px;
      height: 272px;
      margin-top: 50px;
    }
    @include mobile {
      width: 344px;
      height: 230px;
      bottom: 420px;
      position: absolute;
    }
  }
}

#mobile {
  @include tablet {
    display: none;
  }
}








