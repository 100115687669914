@use '../../styles/base.scss' as *;

.section {
  margin: 0 auto;
  margin-bottom: 8rem;
  position: relative;
  width: 1194px;
  @include laptop {
    width: 1240px;
  }
  @include tablet {
    width: 720px;
  }
  @include mobile {
    width: 375px;
    margin-top: 170px;
    margin-bottom: 0px;
  }
 
  &__content {
    width: 720px;
    height: 500px;
    background-color: #eeefef;
    display: flex;
    flex-direction: column;
    @include laptop {
      width: 748px;
    }
    @include tablet {
      width: 428px;
      height: 410px;
    }
    @include mobile {
      width: 100%;
      height: fit-content;
      padding-top: 146px;
      padding-bottom: 30px;
    }
    &__text {
      width: 360px;
      height: fit-content;
      margin-left: 30px;
      margin-top: 95px;
      text-align: left;
      @include tablet {
        width: 220px;
        height: 290px;
        margin-top: 30px;
        margin-left: 30px;
      }
      @include  mobile {
        width: 295px;
        height: fit-content;
        margin: 0 auto;
      } 
      h1 {
        @include subheading;
        letter-spacing: 1.8px;
        line-height: 20px;
        margin-bottom: 20px;
        @include tablet {
          margin-bottom: 10px;
        }
        @include mobile {
          margin-top: 25px;
          margin-bottom: 30px;
          letter-spacing: 1.6px;
        }
      } 
      p {
        @include bodyText;
        letter-spacing: 0.11px ;
        line-height: 22px;
        text-align: left;
        @include mobile {
          text-align: center;
          letter-spacing: 0.13px;
        }
      }  
    }
    &__buttonWrapper {
      width: fit-content;
      margin-left: 30px;
      margin-top: 20px;
      @include tablet {
        margin-top: 50px;
      }
      @include mobile {
        margin: 30px auto;
        margin-bottom: 55px;
      }
    }
    button {
      background-color: #eeefef;
      border: 1px solid #1a1a1a;
      color: #1a1a1a;
      width: 180px;
      height: 46px;
      font: bold 8.25px 'Alright-Sans';
      letter-spacing: 1.65px;
      text-transform: uppercase;  
      @include mobile {
        font-family: 'Arial';
      }
    }
    button:hover {
      background-color: #1a1a1a;
      color: white;
    }
  }
  &__image {
    width: 771px;
    height: 534px;
    position: absolute;
    top: 50px;
    right: 0;
    @include laptop {
      width: 801px;
    }
    @include tablet {
      top: 70px;
      width: 449px;
      height: 299px;
    }
    @include mobile {
      width: 344px;
      height: 230px;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      top: -115px;
    }
  }
}
.imageMobile {
  display: none;
  // @include mobile {
  //   display: block;
  //   width: 344px;
  //   height: 230px;
  // }

}
#mobile {
  @include tablet {
    color: red !important;
    display: none !important;
  }
  @include mobile {
    display: block;
  }
}

#brMobile {
  display: none;
  @include tablet {
    display: block;
  }
  @include mobile {
    display: none;
  }
}

.eventsPage {
  display: none;
}