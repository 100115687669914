@use '../../styles/base.scss' as *;

.nav {
    margin-bottom: 0.5rem;
    position: fixed;
    overflow: hidden;
    z-index: 99;
    width: 100vw; 
    top: 0;
    position: fixed;
    padding: 0 2.4rem;
    @media only screen and (max-width: 1440px) {
        padding: 0 2rem;
    }
    @media only screen and (max-width: 768px) {
        height: 57px;
        padding-left: 0;
        padding-right: 0;
    }
    @media only screen and (max-width: 500px) {
        height: 53px;
    }
    @media only screen and (max-width: 400px) {
        height: 47.5px;
    }
    img {
        margin: 0 auto;
    }
    &.active {
        background: white ;
    }
    &__newsletter {
        color: white;
        position: absolute;
        top: 35px;
        left: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 35px;
        font: normal 8.25px 'Alright-Sans';
        letter-spacing: 1.6px;
        text-transform: uppercase;
        @media only screen and (max-width: 1440px) {
            gap: 20px;
            top: 27px;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
        &.active {
            color: #1a1a1a;
        }
    }
    &__bookNowButtonWrapper {
        position: absolute;
        top: 15px;
        right: 40px;
        
        @media only screen and (max-width: 1440px) {
            top: 17px;
            right: 32px;
        }
        @media only screen and (max-width: 768px) {
            top: 10px;
            right: 19px;
        }
        @media only screen and (max-width: 500px) {
            top: 10px;
            right: 10px;
        }
        @media only screen and (max-width: 400px) {
            right: 12px;
        }
        button {
            background: white;
            color: #1a1a1a;
            border: none;
            width: 146px;
            height: 48px;
            font: bolder 13px 'Alright-Sans';
            letter-spacing: 2.6px;
            text-transform: uppercase;
            @media only screen and (max-width: 1440px) {
                width: 110px;
                height: 36px;
                font-size: 10.5px;
                letter-spacing: 2.1px;
            }
            @media only screen and (max-width: 768px) {
                width: 94px;
                height: 36px;
                font-size: 8.25px;
                letter-spacing: 1.65px;
            }
            @media only screen and (max-width: 500px) {
                width: 86px;
                height: 33px;
            }
            @media only screen and (max-width: 400px) {
                width: 55px;
                height: 26px;
            }
            @include mobile {
                font-family: 'Arial';
            }
        }
        span {
            @media only screen and (max-width: 400px) {
                display: none;
            }
        }
    }
    &__headline {
        width: 100%;
        text-transform: uppercase;
        color: white;
        padding-top: 24px;
        padding-bottom: 15px;
        @media only screen and (max-width: 1440px) {
            padding-top: 15px;
        }
        @media only screen and (max-width: 768px) {
            margin-bottom: 6.5px;

        }
        @media only screen and (max-width: 500px) {
            margin-bottom: 7px;
        }

        &.active {
            color: #1a1a1a;
        }
        .logoImage {
            display: none;
            &.active {
                display: block;
                width: 222px;
                height: 30px;
                margin-top: 6px;
                margin-bottom: 4.6px;
                @media only screen and (max-width: 1440px) {
                    width: 167px;
                    height: 22px;
                }
                @media only screen and (max-width: 768px) {
                    width: 170px;
                    height: 23px;
                }
                @media only screen and (max-width: 500px) {
                    width: 119px;
                    height: 16px;
                    margin-top: 3px;
                }
                @media only screen and (max-width: 400px) {
                    width: 119px;
                    height: 16px;
                }
            }
        }
        
        i { // Mobile Bars Icon
            display: none;
            @media only screen and (max-width: 768px) {
                display: block;
                position: absolute;
                top: 23px;
                left: 23px;
                z-index: 2222;
                font-size: 18px;
                width: 50px;
            }
            @media only screen and (max-width:500px) {
                font-size: 18px;
                top: 20px;
            }
            @media only screen and (max-width:400px) {
                font-size: 16px;
                top: 18px;
            }
        }
    }
    &__links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 0.1rem;
        margin-bottom: 0.5rem;
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    &__item {
        padding:0 0.7rem;
        margin-top: 0.5rem;
        @media only screen and (max-width: 1440px) {
            padding: 0 0.5rem;
        }
        a {
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
        &__element {
            color: white;
            opacity: 1;
            font: normal 11.25px 'Baskerville';
            letter-spacing: 0.5px;
            text-transform: uppercase;
            &.active {
                color: #1a1a1a;
            }
            @media only screen and (max-width: 768px) {
               color: #1a1a1a;
            }
        }
        .dot {
            color: white;
            font-size: 4px;
            padding-top: 11px;
            &.active {
                color:  #1a1a1a;
            }
        }
    }
    &__mobile { // mobile navigation menu
        display: none;
        a {
            color: black;
            font: bold 8.25px 'Arial';
            letter-spacing: 1.65px;
            text-transform: uppercase;
            margin-left: 17px;
        }
        &.active {
            display: flex;
            flex-direction: column;
            position: fixed;
            z-index: 999;
            top: 57px;
            left: 0px;
            text-decoration: none;
            background-color: white;
            width: 90%;
            height: 100%;
            border-top: 0.5px solid #1a1a1a;
            @media only screen and (max-width: 500px) {
                top: 53px;
            }
            @media only screen and (max-width: 400px) {
                top: 47.5px;
            }
        }
        &__row {
            display: flex;
            justify-content: space-between;
            border-top: 0.5px solid #1a1a1a;
            padding-bottom: 17px;
            padding-top: 17px;
            margin-left: 10px;
            margin-right: 10px;
            i {
                padding-right: 0.5rem;
            }
            &__contact {
                padding-top: 20px;
                padding-bottom: 15px;
                i {
                    margin-left: 0.5rem;
                    font-size: 14px;
                }
            }   
        }
    }
}

hr {
    margin-left: auto;
    margin-right: auto;
    border: 0;
    border-top: 0.5px solid white;
    &.active {
        border-top: 1px solid #1a1a1a;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: -14px; 
    }
    @media only screen and (max-width: 500px) {
        margin-top: -8.6px;
    }
    @media only screen and (max-width: 400px) {
        margin-top: -14px;
    }

  }
#nav-icon {
    color: white;
}
#nav-icon-active {
    color: #1a1a1a;
}
#buttonActive {
    background-color: #1a1a1a;
    color: white;
}
