@use '../../styles/base.scss' as *;

a {
  text-decoration: none !important;
}

.sectionWrapper {
  background-color: #1a1a1a;
  width: 100vw;
}


.section {
  width: 1920px;
  height: 458px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include laptop {
    width: 1440px;
    height: 343px;
  }
  @include tablet {
    width: 768px;
    height: 320px;
  }
  @include mobile {
    width: 375px ;
    height: fit-content;
  }

  &__stayConnected {
    display: none;
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    h1 {
      font: normal 19.5px 'Baskerville';
      letter-spacing: 1.95px;
      line-height: 38px;
      margin-top: 38px;
      color: white;
      @include mobile {
        line-height: normal;
        margin-bottom: 1rem;
      }
    }
    h2 {
      font: normal 19.5px 'Baskerville';
      letter-spacing: 1.95px;
      line-height: 38px;
      color: white;
      @include mobile {
        display: none;
      }
    }
    img {
      width: 170px;
      height: 23px;
    }
    &__button {
      margin: 0 auto;
      color: white;
      display: block;
      width: 180px;
      height: 46px;
      border: 1px solid white;
      background-color: #1a1a1a;
      opacity: 1;
      font: bold 8.25px 'Alright-Sans';
      letter-spacing: 1.8px;
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 94px;
      @include mobile {
        width: 162px;
        height: 41px;
        margin-top: 11px;
        margin-bottom: 60px;
      }
    }
    &__button:hover {
      background-color: #1a1a1a;
      color: white;
    }
  }

  &__navigation {
    margin: 0 160px;
    margin-top: 83px;
    height: 170px;
    display: flex;
    justify-content: flex-start;
    gap: 146px;
    color: white !important;
    @include laptop {
      margin: 0 124px;
      margin-top: 62px;
      gap: 96px;
    }
    @include tablet {
      padding: 0 39px;
      margin: 0;
      margin-top: 30px;
      border: none;
      gap: 40px;
    }
    @include mobile {
      padding: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border: none;
      gap: 0;
      height: fit-content;
    }
    &__logo {
      @include laptop {
        margin-right: 16px;
      }
      @include tablet {
        display: none;
      }
      img {
        width: 255px;
        height: 34px;
        @media only screen and (max-width: 850px) {
          display: none;
        }
        @media only screen and (max-width: 760px) {
          display: block;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
      }
    }
    &__element {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items:stretch;
      text-align: left;
      gap: 26px;
      @include laptop {
        gap: 19px;
      }
      @include mobile {
        gap: 0;
        width: 312px;
        margin: 0 33px;
        border-bottom: 1px solid white;
      }
      h2 {
        margin-bottom: 22px;
        width: max-content;
        color: white;
        font: normal bold 8.25px 'Alright-Sans';
        letter-spacing: 1.65px;
        @include laptop {
          margin-bottom: 15px;
        }
        @include mobile {
          margin: 24px 0;
          font-size: 10px;
          letter-spacing: 2px;
        }
      }
      p {
        font: bold 9px 'Alright-Sans';
        letter-spacing: 0.23px;
        width: max-content;
        color: white;
        @include mobile {
          display: none;
        }
      }
     
    }
  }
  &__links {
    border-top: 1px solid white;
    margin: 0 160px;
    margin-top: 92px;
    padding: 45px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    font-family: $ff-1;
    text-transform: uppercase;
    color: white !important;
    @include laptop {
      margin: 0 124px;
      margin-top: 70px;
      padding: 31px 0;
    }
    @include tablet {
      margin: 28px 0;  
      margin-top: 38px;    
      padding: 0 39px;
      border: none;
    }
    @include mobile {
      width: 312px;
      margin: 0 33px;
      padding: 0;
      padding-top: 36px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &__icons {
      display: flex;
      justify-content: space-between;
      gap: 33px;
      align-items: center;
      &__wrapper {
        background: white;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 12px;
          color: black;
        }
      }
    }
    &__rights {
      font: bold 9px 'Alright-Sans';
      letter-spacing: 1.8px;
      @include mobile {
        margin: 41px auto;
        margin-top: 48px;
      }
    }
  }
}
#aboutUs {
  @include mobile {
    border-top: 1px solid white;

  }
}
