// @use '../../../styles/base.scss' as *;
@use '../../styles/base.scss' as *;


.section {
  position: relative;
  overflow: hidden;
  &__alert {
    display: none !important;
    padding: 0.35rem 4rem !important;
    z-index: 100;
    position: fixed !important;
    top: 2.5rem;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    transition: width 2s, height 4s;
    -webkit-transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -o-transition: opacity 2s ease-in-out;
    transition: opacity 2s ease-in-out;

    &--active {
      display: flex !important;
    }
  }
}

.button {
  display: block;
  margin: 3rem auto;
  width: 426px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    margin: 2rem auto;
    width: 270px;
    height: 50px;
  }
}

.image {
  width: 1920px;
  height: 579px;
  margin: 0 auto;
  object-fit: cover;
  @include laptop {
    width: 1440px;
    height: 434px;
  }
  @include tablet {
    width: 768px;
    height: 231px;
  }
  @include mobile {
    display: none;
  }
}

.heroImage {
  width: 100%;
  height: 50%;
  max-height: 1500px;
  object-fit: cover;
  @media only screen and (max-width: 2500px) {
    height: 807px;
  }
  @include laptop {
    height: 614px;
  }
  @include tablet {
    height: 426px;
  }
  @include mobile {
    height: 250px;
  }
}

