@use '../../styles/base.scss' as *;

.section {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    @include tablet {
        width: 768px;
    }
  
    @include mobile {
        display: none;
    }
    &__mainHeading {
        margin-bottom: 110px;
        @include subheading;
        letter-spacing: 1.8px;
    }
    &__brochure {
        margin: 0 auto;
        width: 1375px;
        display: flex;
        align-items: center;
        gap: 97px;
        @include laptop {
            width: 1239px;
            gap: 104px;
        }
        @include tablet {
            flex-direction: column;
            width: 768px;
        }
        img {
            width: 608px;
            height: 1060px;
            object-fit: cover;
            @include tablet {
                width: 508px;
                height: 756px;
            }
        }
        &__specifications { 
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            width: 600px;
            &__info {  
                margin-top: 17px;
                display: flex;
                flex-direction: column;
             
                &__data {
                    display: flex;
                }
                h1 {
                    margin-bottom: 11px;
                    font: normal 11.25px "Alright-Sans";
                    line-height: 11px;
                    letter-spacing: 1.69px;
                    text-transform: uppercase;
                    color: #1a1a1a;
                }
                h2 {
                    width: 149px;
                    font: bold 9px "Alright-Sans";
                    letter-spacing: 0.09px;
                    line-height: 22px;
                    color: #1a1a1a;
                }
                p {
                    width: 450px;
                    font: normal normal normal 9px "Arial";
                    letter-spacing: 0.09px;
                    color: #1a1a1a;
                    line-height: 22px;
                }
            }
        }
    }
}











