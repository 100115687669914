@use '../../styles/base.scss' as *;

.heading {
    @include subheading;
    letter-spacing: 3.15px;
    line-height: 22px;
    text-align: center;
    margin-top: 98px;
    margin-bottom: 87px;
    @include mobile {
        margin: 50px auto;
    }
}

.image {
    margin: 0 auto;
    margin-top: 250px;
    width: 1920px;
    height: 924px;
    object-fit: cover;
    @include laptop {
        width: 1440px;
        height: 693px;
    }
    @include tablet {
        width: 768px;
        height: 370px;
        margin-top: 50px;
    }
    @include mobile {
        margin-top: 50px;
        width: 100%;
        height: 181px;
    }
}
.color {
    color: white !important;
}