@use '../../styles/base.scss' as *;

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 130px auto;
    @include tablet {
        margin: 60px auto;
    }
    @include mobile {
        margin: 0px auto;
        margin-bottom: 50px;
    }
    a {
        text-decoration: none;
        color: #1a1a1a;
    }
    h1 {
        @include subheading;
        letter-spacing: 3.15px;
    }
    p {
        margin-top: 20px;
        font: 11.25px 'Arial';
        letter-spacing: 0.28px;
        line-height: 16px;
        font-weight: 100;
        @include mobile {
            font-family: 'Arial';
        }
    }
    button {
        background: white;
        border: 1px solid #707070;
        color: #1a1a1a;
        width: 110px;
        height: 36px;
        margin-top: 20px;
        font: bold 10.5px 'Alright-Sans';
        text-transform: uppercase;
        letter-spacing: 2.1px;
        @include mobile {
            font-family: 'Arial';
            color: white;
            background-color: #1a1a1a;
        }
    }
    button:hover {
        background-color: #1a1a1a;
        color: white;
      }
    span {
        @include mobile {
            display: none;
        }
    }
}
