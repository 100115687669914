@use '../../styles/base.scss' as *;

.section {
  margin: 0 auto;
  width: 1194px;
  color: $primary-color;
  padding: 3rem 0;
  margin-top: 4rem;
  @include laptop {
    width: 1240px;
  }
  @include tablet {
    width: 720px;
  }
  @include mobile {
    width: 375px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-top: 0;
  }
  &__images {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 500px) {
      margin: 0 1rem !important;
    }
  }
  &__image {
    max-height: 6rem;
    width: 4.5rem;
    object-fit: contain;
    @media only screen and (max-width: 900px) {
      max-height: 4rem;
      width: 4rem;
    }
    @include tablet {
      max-width: 3.2rem;
      width: 3.2rem;
    }
    @include mobile {
      max-height: 2rem;
      width: 2rem;
    }
  }
  h1 {
    @include subheading;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-align: center;
    opacity: 1;
  }
  p {
    @include bodyText;
    line-height: 22px;
    letter-spacing: 0.11px;
    text-align: center;
    opacity: 1;
    width: 690px;
    margin: 20px auto;
    @include tablet {
      width: 436px;
    }
    @include mobile {
      width: 330px;
    }
  }
  h3 {
    @include subheading;
    letter-spacing: 1.44px;
    line-height: 20px;
    margin: 7rem 0 5rem 0;
    margin-top: 8rem;
    text-align: center;
    opacity: 1;
    @include laptop {
      margin-top: 70px;
      margin-bottom: 62px;
    }
    @media only screen and (max-width: 500px) {
      margin: 3rem 0;
    }
  }
  &__arrowContainer {
    position: relative;
  }
  &__arrow {
    width: 40px;
    height: 40px;
    background-color: black;
    border: none;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 13%;
  }
  &__button {
    color: black;
    display: block;
    margin: 3rem auto;
    width: 210px;
    height: 46px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    font: bold 8.25px 'Alright-Sans';
    letter-spacing: 1.8px;
    cursor: pointer;
    margin-bottom: 9rem;
    @include tablet {
      margin-top: 133px;
      margin-bottom: 51px;
    }
    @include mobile {
      margin-top: 90px;
      margin-bottom: 82px;
      font-family: 'Arial';
    }
  }
  &__button:hover {
    background-color: #1a1a1a;
    color: white;
  }
  &__hero {
    display: none;
    width: 100%;
    height: 13rem;
    @media only screen and (max-width: 400px) {
      display: inline;
    }
  }
}
