@mixin laptop { // from 1440 - 2560
  @media only screen and (max-width: 1919px) { 
    @content;
  }
}
@mixin tablet { // from 768 - 1439
  @media only screen and (max-width: 1439px) {
    @content;
  }
}
@mixin mobile { // from 320 - 767
  @media only screen and (max-width: 767px) {
    @content;
  }
}

// Mobile First Approcah
@mixin tabletMF {
  @media only screen and (min-width: 320px) {
    @content;
  }
}
@mixin desktopMF {
  @media only screen and (min-width: 760px) {
    @content;
  }
}

@mixin wrapper {
  width: 85%;
  max-width: 1616px;
  margin: 1.5rem auto;
}
