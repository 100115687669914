@use '../../styles/base.scss' as *;

.section {
    position: relative;
    margin: 0 auto;
    max-width: 1194px;
    height: 681px;
    @include laptop {
        max-width: 1240px;
        height: 755px;
    }
    @include tablet {
        max-width: 768px;
        padding: 0 20px;
        height: 512px;
    }
    @include mobile {
        max-width: 100%;
        height: fit-content;
    }
    img {
        width: 454px;
        height: 681px;
        z-index: 1;
        position: absolute;
        @include laptop {
            width: 548px;
            height: 755px; 
        }
        @include tablet {
            width: 334px;
            height: 461px;
        }
        @include mobile {
            display: none;
        }
    }
    &__content {
        position: absolute;
        z-index: 0;
        top: 190px;
        left: 425px;
        background-color: #EEEFEF;
        width: 770px;
        height: 434px;
        @include laptop {
            width: 770px;
            height: 434px;
            left: 450px;
            top: 200px;
        }
        @include tablet {
            width: 451px;
            height: 472px;
            top: 40px;
            left: 297px;
        }
        @include mobile {
            background-color: white;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            margin: 0;
            // padding-top: 60px;
            padding-bottom: 20px;
            margin: 0 auto;
            width: 100%;
            height: fit-content;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__text {
            width: 618px;
            margin-left: 80px;
            margin-top: 60px;
            text-align: left;  
            @include laptop {
             width: 610px;
             margin-left: 135px;
             margin-top: 0;
            }
            @include tablet {
                width: 346px;
                margin-left: 90px;
                margin-top: 10px;
            }
            @include mobile {
                padding: 0;
                margin: 0;
                width: 320px;
                height: 570px;
            }
            
            &__content {
                @include mobile {
                    // width: 350px;
                    height: fit-content;
                    height: 850px;
                }
                @media only screen and (max-width: 340px) {
                    width: fit-content;
                }

 
            }
        }
    }
    p {
        @include bodyText;
        line-height: 16px ;
        letter-spacing: 0.11px;
        margin-bottom: 1rem;
        @include mobile {
            line-height: 20px !important;            
        }
    }
    h2 {
        margin-bottom: 20px;
        margin-top: 40px;
        @include subheading;
        letter-spacing:1.8px !important;
        line-height: 20px;
        @include tablet {
            // letter-spacing: 1.8pt;
        }
        @include mobile {
            letter-spacing: 1.57px;
        }
    }
}

#mainHeading {
        text-align: center;
        margin-top: 94px;
        margin-bottom: 110px;
        @include subheading;
        letter-spacing:1.8px !important;
        line-height: 20px;
        @include laptop {
            margin-top: 70px;
            margin-bottom: 90px;
        }
        @include tablet {
            margin-top: 50px;
            margin-bottom: 100px;
        }
        @include mobile {
            letter-spacing: 1.57px;
            margin-bottom: 0px;
            padding: 0 20px;
            margin: 0 auto;
            width: 375px;
            margin-top: 50px;
        }
}
