// Typography styles

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&family=Playfair+Display+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap');
$ff-1: 'Alright-Sans', serif;
$ff-2: 'Baskerville', sans-serif;
$ff-3: 'FontsFree-1', sans-serif;
$ff-4: 'FontsFree-2', sans-serif;
$ff-5: 'FontsFree-3', sans-serif;
$ff-6: 'FontsFree-4', sans-serif;



$fs-heading-large: 3.125rem; // 50px
$fs-heading-standard: 3rem; // 48px
$fs-heading-medium: 2.188rem; // 35px
$fs-heading-small: 1.25rem; // 20px

$fs-paragraph-large: 0.875rem; // 14px
$fs-paragraph-medium: 0.688rem; // 11px
$fs-paragraph-small: 0.625rem; // 10px

$fs-paragraph: 1rem; // 16px


@font-face {
  font-family: 'Alright-Sans';
  src: url('../fonts/Alright\ Sans.ttf');
}
@font-face {
  font-family: 'Baskerville';
  src: url('../fonts/Baskerville.ttc');
}
@font-face {
  font-family: 'FontsFree-1';
  src: url('../fonts/FontsFree-1.ttf');
}
@font-face {
  font-family: 'FontsFree-2';
  src: url('../fonts/FontsFree-2.ttf');
}
@font-face {
  font-family: 'FontsFree-3';
  src: url('../fonts/FontsFree-3.ttf');
}
@font-face {
  font-family: 'FontsFree-4';
  src: url('../fonts/FontsFree-4.ttf');
}


@mixin bodyTextStyling {
  font: normal normal normal 16px 'Alright-Sans' !important;
  letter-spacing: 0.5px !important;
  line-height: 32px !important;
  color: #1a1a1a !important;
  @media only screen and (max-width: 500px) {
    font-size: 13pt !important;
    letter-spacing: 0.13pt !important;
    line-height: 20pt !important;
  }
}
@mixin subheadingStyling {
  font: normal normal normal 25px 'Baskerville' !important;
  letter-spacing: 2px !important;
  line-height: 40px !important;
  color: #1a1a1a !important;
  text-transform: uppercase;
  @media only screen and (max-width: 500px) {
    font-size: 18pt !important;
    letter-spacing: 1.8pt !important;
    line-height: 20pt !important;
  }
}
@mixin mainHeadingStyling {
  font: normal normal normal 35px 'Baskerville' !important;
  letter-spacing: 5px !important;
  line-height: 40px !important;
  color: #1a1a1a !important;
  text-transform: uppercase;
  @media only screen and (max-width: 500px) {
    font-size: 18pt !important;
    letter-spacing: 1.8pt !important;
    line-height: 20pt !important;
  }
}

// font, font-size,color
@mixin bodyText {
  font: normal 11.25px 'Alright-Sans';
  color: #1a1a1a;
  @media only screen and (max-width: 1439px) { // from 321 - 1440
    font-size: 11.25px;
  }
}
// font, font-size, color, text-transform
@mixin subheading {
  font: normal 18px 'Baskerville' ;
  color: #1a1a1a;
  text-transform: uppercase;
  @media only screen and (max-width: 1439px) { // from 768 - 1440
    font-size: 18px;
  }
  @media only screen and (max-width: 767px) { // from 320 - 767
    font-size: 15.75px;
  }
}





