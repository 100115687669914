@use '../../styles/base.scss' as *;

.section {
  @include wrapper;
  @media only screen and (max-width: 1660px) {
    width: 90%;
  }
  @media only screen and (max-width: 1250px) {
    width: 98% !important;
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
    margin-bottom: 0px;
  }
  &__heading {
    @include mainHeadingStyling;
    margin: 5rem auto 0 auto;
    text-align: center;
    max-width: 50ch;

    @media only screen and (max-width: 1000px) {
      margin-bottom: 5rem;
    }
    @media only screen and (max-width: 600px) {
      margin: 1rem 0;
    }
    @media only screen and (max-width: 500px) {
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
  }
  &__wrapper {
    height: 55rem;
    height: 70rem;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 1000px) {
      height: 45rem;
    }
    @media only screen and (max-width: 800px) {
      height: 40rem;
    }
    @media only screen and (max-width: 1000px) {
      height: fit-content;
      margin: 0;
      padding: 0;
    }
  }
  &__container {
    flex: 1;
    padding: 3rem;
    @media only screen and (max-width: 1000px) {
      padding: 1rem;
    }
    &--first {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
    &--second {
      @media only screen and (max-width: 1000px) {
        height: fit-content;
      }
    }
  }
  &__content {
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    @media only screen and (max-width: 1000px) {
      justify-content: center;
    }
    @media only screen and (max-width: 1000px) {
      height: fit-content;
    }
  }
  &__image {
    opacity: 1;
    object-fit: fill;
    &--second {
      width: 85%;
      height: 30% !important;
      max-width: 440px;
      max-height: 600px;
      object-position: 40% bottom;
    }
    &--first {
      width: 100%;
      height: 95%;
      padding-top: 2rem;
      object-position: left bottom;
      z-index: 10;
    }
  }
  &__text {
    padding: 1rem 3rem 1rem 5rem;
    margin: 0 0 -3rem -8rem;
    background-color: #eeefef;
    width: 133%;
    max-width: 1020px;
    @media only screen and (max-width: 1000px) {
      width: fit-content;
      margin: 0;
      padding: 2rem 3rem;
    }
    @media only screen and (max-width: 600px) {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
    @media only screen and (max-width: 500px) {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
    h3 {
      @include subheading;
      
      text-align: left;
      margin-bottom: 3rem;
      @media only screen and (max-width: 1200px) {
        margin-bottom: 1rem;
      }
      @media only screen and (max-width: 1000px) {
        margin-bottom: 0;
      }
    }
    p {
      @include bodyText;
      margin: 3.5rem 0;
      text-align: left;
      opacity: 1;
      @media only screen and (max-width: 1700px) {
        line-height: 29px;
        font-size: 18px;
        margin: 2.85rem 0;
      }
      @media only screen and (max-width: 1500px) {
        margin: 2.5rem 0;
      }
      @media only screen and (max-width: 400px) {
        margin: 2rem 0;
      }
    }

    button {
      width: 220px;
      height: 50px;
      background: #1a1a1a00 0% 0% no-repeat padding-box;
      border: 1px solid #000000;
      opacity: 1;
    }
  }
}
#about {
  margin-top: 2rem;
}