@use '../../styles/base.scss' as *;

.section {
  // background-color: red;
  width: 1375px;
  margin: 150px auto;
  color: $primary-color;
  height: fit-content;
  @include laptop {
    width: 1239px;
    padding: 0;
  }
  @include tablet {
    width: 768px;
    margin: 100px auto;
  }
  @include mobile {
    margin-top: 60px;
    margin-bottom: 50px;
    width: 350px;
    height: 1050px;
  }
  &__text {
    @include bodyText;
    letter-spacing: 0.28px;
    line-height: 16px;
    margin: 30px auto !important;
    text-align: center;
    justify-content: center;
    max-width: 690px !important;
    @include mobile {
      width: 320px;
    }
  }
  &__image {
    margin-top: 60px !important;
    margin-bottom: 108px !important;
    width: 1192px !important;
    height: 779px !important;
    object-fit: cover;
    @include tablet {
      width: 727px !important;
      height: 475px !important;
    }
    @include mobile {
      margin-top: 65px !important;
      margin-bottom: 45px !important;
      width: 334px !important;
      height: 218px !important;
    }
  }
  &__content {
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0;
    height: fit-content;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  &__buttons {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 120px;
    height: 100%;
    @include tablet {
      margin-left: 20px;
    }
    @include mobile {
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin: 0 auto;
    }
  }
  &__button {
    background-color: white;
    width: 100%;
    position: relative;
    font: normal normal bold 8.25px 'Alright-Sans';
    letter-spacing: 3px;
    color: #1a1a1a;
    z-index: 10;
    border: none;
    text-transform: uppercase;
    height: 50px;
    margin-bottom: 50px;
    @include laptop {
      font-size: 8.25px;
      letter-spacing: 1.65px;
    }
    @include mobile {
      margin-bottom: 0px;
      font-family: 'Arial';
      height: 40px;
      width: 100px;
      min-width: 110px;
    }
  }
  &__span {
    opacity: 0;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    height: 1px;
    background-color: $primary-color-lt;
    bottom: 0;
    left: 0px;
    z-index: 1;
    transition: width 1s, height 4s;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    @include mobile {
      // width: 80%;
      height: 1px;
      text-align: center;
      // left: 5px;
      // right: 5px;
    }
    &--active {
      opacity: 1;
    }
    &--not-active {
      opacity: 0;
    }
  }
  &__container {
    position: relative;
    height: fit-content;
    width: 800px;
    margin: 0 auto;
    @include mobile {
      margin-left: 0;
      margin-top: 4rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    // @media only screen and (max-width: 500px) {
    //   margin-left: 1rem;
    //   margin-right: 1rem;
    // }
  }
  &__item {
    position: absolute;
    opacity: 0;
    height: fit-content;
    transition: width 2s, height 4s;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    @include tablet {
      width: 600px;
      margin-left: 33px;
    }
    @include mobile {
      margin-left: 0;
      width: 350px;
      left: 50%;
      transform: translateX(-50%);
    }
    &--active {
      opacity: 1;
      padding-bottom: -40px;
    }
    &--not-active {
      opacity: 0;
    }
  }
  span {
    position: absolute;
  }
  h2 {
    @include subheading;
    letter-spacing: 1.8px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 2rem;
    }
  }
  p {
    @include bodyText;
    letter-spacing: 0.28px;
    line-height: 16px;
    margin: 0.5rem 0 0 0;
    text-align: center;
    max-width: 939px;
    @include mobile {
      margin-top: -1rem;
      width: 320px;
      margin: 0 auto;
      margin-top: -1rem;
      line-height: 20px;
    }
  }
  img {
    margin: 0 auto;
    width: 800px;
    height: 250px;
    @include mobile {
      height: 180px;
      margin: 2rem auto;
    }
  } 
}
