@use '../../styles/base.scss' as *;

.section {
  width: 1194px;
  margin: 136px auto;
  color: $primary-color;
  @include laptop {
    width: 1240px;
    margin: 93px auto;
  }
  @include tablet {
    width: 100%;
    margin: 81px 0;
  }
  @include mobile {
    margin: 50px auto;

  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    @include subheading;
    letter-spacing: 1.8px;
    line-height: 25px;
    text-align: center;
    @include mobile {
      line-height: 22px;
      letter-spacing: 1.6px;
    }
  }
  p {
    margin-top: 20px;
    margin-bottom: 62px;
    width: 690px;
    @include bodyText;
    letter-spacing: 0.11px;
    line-height: 16px;
    text-align: center;
    @include laptop {
      margin-top: 28px;
      margin-bottom: 21px;
      width: 720px;
    }
    @include tablet {
      width: 436px;
    }
    @include mobile {
      margin-top: 20px 0;
      width: 320px;
      line-height: 20px;
    }
  }
  button {
    background-color: white;
    border: 1px solid #1a1a1a;
    color: #1a1a1a;
    width: 180px;
    height: 46px;
    font: bold 8.25px 'Alright-Sans';
    letter-spacing: 1.65px;
    text-transform: uppercase;  
    margin-top: 15px;  
    @include mobile {
      font-family: 'Arial';
    }
  }
  button:hover {
    background-color: #1a1a1a;
    color: white;
  }
}
