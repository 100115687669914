@use '../../../styles/base.scss' as *;

.section {
  @include wrapper;
  color: $primary-color;
  @media only screen and (max-width: 500px) {
    width: 93%;
  }

  &__contactInfo {
    & > * {
      margin: 0 auto;
    }

    h2,
    h3,
    h5,
    p {
      text-align: center;
    }

    div {
      margin: 1.25rem 0;
    }

    h3 {
      margin: 0.75rem 0;
      font: normal normal normal 11.25px 'Arial';
      letter-spacing: 0.11px;
      color: #1a1a1a;
      @include mobile {
        margin: 0.25rem 0;
        font-size: 11px;
        letter-spacing: 0.11px;
      }
    }

    h5 {
      margin: 0.75rem 0;
      margin-bottom: 3rem;
      font: normal normal normal 11.25px 'Arial';
      letter-spacing: 0.11px;
      color: #1a1a1a;
      @include mobile {
        
        margin: 0.8rem 0;
      }
    }

    p {
      margin: 4rem auto;
      margin-bottom: 7rem;
      max-width: 83ch;
      @include bodyText;
      letter-spacing: 0.11px;
      @include mobile {
        margin: 4rem auto;
        font-size: 11px;
        letter-spacing: 0.11px;
      }
    }
  }

  form {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    @media only screen and (max-width: 500px) {
      display: block;
      text-align: center;
    }
  }

  &__heading {
    margin-top: 5rem;
    margin-bottom: 4rem;
    text-align: center;
    @include subheading;
    letter-spacing: 1.8px;
    line-height: 20px;
    @include laptop {
      margin-top: 3rem;
    }

    @include tablet {
      margin-top: 4rem;
    }
    @include mobile {
      letter-spacing: 1.6px;
      line-height: 18px;
      margin-top: 5rem;
      margin-bottom: 3rem;
    }
  }

  &__inputs {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }

  &__inputContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1rem auto;
    align-items: center;
    font: normal normal bold 15px 'Arial';
    letter-spacing: 0.15px;

    input {
      width: 100%;
      max-width: 400px;
      height: 40px;
      border: 1px solid var(--unnamed-color-707070);
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      opacity: 1;
    }

    textarea {
      max-width: 400px;
      width: 100%;
      // min-height: 150px;
      height: 139px;
    }

    label {
      font: bold 11.25px 'Arial';
      letter-spacing: 0.15px;
      text-transform: uppercase;
      width: 100%;
      max-width: 400px;
      text-align: left;
      margin-bottom: 0.5rem;
      @include mobile {
        margin-bottom: 0.5rem;
        font-size: 8.25px;
        letter-spacing: 0.08px;
      }
    }
  }

  &__button {
    font: bold 8.25px 'Alright-Sans';
    letter-spacing: 2.25px;
    margin: 4rem auto;
    margin-bottom: 6rem;
    border: 1px solid var(--unnamed-color-707070);
    background: #1a1a1a 0% 0% no-repeat padding-box;
    border: 1px solid #1a1a1a;
    width: 210px;
    height: 46px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;

    @include mobile {
      margin: 2.75rem auto;
      width: 335px;
      height: 42px;
      font-size: 8.25px;

    }
  }
}
