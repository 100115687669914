@use '../../styles/base.scss' as *;

.wrap {
    position: relative;
}
.heroImage {
  height: 50%;
  max-height: 1200px;
  width: 100%;
  object-fit: cover;

  @media only screen and (max-width: 2500px) {
    height: 1094px;
  }
  @include laptop {
    height: 766px;
  }
  @include tablet {
    height: 512px;
  }
  @include mobile {
    height: 50%;
  }
  @media only screen and (max-width: 500px) {
    height: 281px;
  }
}

.text {
    position: absolute;
    top: 40%;
    left: 5%;
    text-transform: uppercase;
    color: #f7f7f7;
    @include tablet {
        display: none
    }

    h2 {
        @include subheading;
        letter-spacing: 3.15px;
        line-height: 22px;
    }
    p {
        @include bodyText;
        letter-spacing: 0.28px;
        line-height: 16px;
        text-transform:none;
        margin-top: 15px;
    }
}

