@use '../../styles/base.scss' as *;

.image {
  object-fit: cover;
  width: 1920px;
  height: 933px;
  margin: 100px auto;
  @include laptop {
    width: 1440px;
    height: 705px;
  }
  @include tablet {
    width: 768px;
    height: 373px;
  }
  @include mobile {
    width: 375px;
    height: 182px;
    margin: 50px auto;
  }
}
 
