@use '../../styles/base.scss' as *;

.section {
    width: 1194px;
    height: 291px;
    margin: 0 auto;
    margin-top: 126px;
    background-color: #645C59;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include laptop {
        width: 1240px;
    }
    @include tablet {
        width: 768px;
        height: 291px;
    }
    @include mobile {
        width: 375px;
        height: 165px;
        margin-top: 20px;
    }
     &__heading {
        @include subheading;
        letter-spacing: 1.8px !important;
        color: white !important;
        @include mobile {
            font-size: 16px;
            letter-spacing: 1.6px;
            text-align: center;
        }
    }
    &__br {
        display: none;
        @include mobile {
            display: block;
        }
    }
    button {
        margin-top: 33px;
        width: 247px;
        height: 46px;
        background-color: white;
        font: bold 8.25px 'Alright-Sans';
        letter-spacing: 1.65px;
        border: none;
        color: #1a1a1a;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        @include tablet {
            margin-top: 50px;
        }
        @include mobile {
            width: 180px;
            height: 46px;
            margin-top: 20px;
            font-family: 'Arial';
        }
    }
    button:hover {
        background-color: #1a1a1a;
        color: white;
    }
}
#number {
    font: bold 8.25px 'Arial';
    @include tablet {
        display: none;
    }
}



