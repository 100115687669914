@use '../../styles/base.scss' as *;

.stories {
    width: 1300px;
    margin: 0 auto;
    @include laptop {
        width: 1240px;
    }
    @include tablet {
        width: 720px;
    }
    @include mobile {
        width: 100%;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 98px;
        @include mobile {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
        }
    }
    h1 {
        @include subheading;
        letter-spacing: 3.15px;
        text-align: center;
        margin: 90px 0;
        @include mobile {
            margin: 50px 0;
        }

    }
}

#story {
    @include mobile {
        margin-bottom: 55px;
    }
}