@use '../../styles/base.scss' as *;

.profilesSection {
  margin: 0 auto;
  margin-bottom: 8rem;
  position: relative;
  width: 1194px;

  @include laptop {
    width: 1240px;
  }

  @include tablet {
    width: 720px;
    margin-bottom: 0;
  }

  @include mobile {
    width: 85%;
    margin-top: 170px;
    margin-bottom: 0;
  }

  .heading {
    @include subheading;
    letter-spacing: 1.8px;
    line-height: 20px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    @include mobile {
      margin-bottom: 2rem;
    }
  }
  
  .profilesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .profileCard {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    width: calc(25% - 20px); /* 4 cards per row, accounting for gap */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: static;
    padding:5px;
  }
  
  .profileImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
  }
  
  .profileInfo {
    padding: 10px;
    text-align: center;
  }
  
  .profileName {
    @include bodyText;
    font-size: 1.2rem;
    margin: 10px 0;
    color: #007bff;
    @include mobile {
      margin-top: -1rem;
      width: 320px;
      margin: 0 auto;
      margin-top: -1rem;
      line-height: 20px;
    }
  }
  
  .profileRole,
  .profileExperience {
    @include bodyText;
    // font-size: 1rem;
    color: #666;
  }
  
  .profileBio {
    @include bodyText;
    letter-spacing: 0.28px;
    max-width: 939px;
    // font-size: 0.9rem;
    color: #444;
    margin-top: 10px;
    // @include mobile {
    //   margin-top: -1rem;
    //   width: 320px;
    //   margin: 0 auto;
    //   margin-top: -1rem;
    //   line-height: 20px;
    // }
  }

  .groupPhotoContainer {
    margin-top: 30px; /* Adjust as needed */
    display: flex;
    justify-content: center;
  }

  .groupPhoto {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Media queries to adjust card width for different screen sizes */
  @include tablet {
    .profileCard {
      width: calc(50% - 20px); /* 2 cards per row on tablets */
    }
  }

  @include mobile {
    .profileCard {
      width: calc(100% - 20px); /* 1 card per row on mobile */
    }
  }
}
