@use '../../../styles/base.scss' as *;

.section {
    @include wrapper;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 116px;
    @include mobile {
      margin-top:50px;
    }

   h1 {
      @include subheading;
      letter-spacing: 3.15px !important;
      text-align: center;
      line-height: 25px;
      @include laptop {
        letter-spacing: 1.8px !important;
      }
      @include tablet {
        letter-spacing: 1.95px !important;
      }
      @include mobile {
        letter-spacing: 1.57px !important;
      }
   }
   p {
      @include bodyText;
        text-align: center;
        width: 792px;
        letter-spacing: 0.28px !important;
        line-height: 16px !important;
        margin: 20px 0;
        @include laptop {
          width: 750px;
          line-height: 22px !important;
          letter-spacing: 0.28px!important;
        }
        @include tablet {
          width: 489px;
          letter-spacing:0.28px !important;
          line-height: 22px !important;
        }
        @include mobile {
          margin: 5px 0;
          width: 335px;
        }
   }
    
}



