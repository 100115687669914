@use '../../styles/base.scss' as *;

.section {
  @include wrapper;
  height: 55rem;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1000px) {
    height: 45rem;
  }
  @media only screen and (max-width: 800px) {
    height: 35rem;
  }
  @media only screen and (max-width: 600px) {
    height: 30rem;
  }
  @media only screen and (max-width: 550px) {
    display: none;
  }
  &__container {
    flex: 1;
    padding: 3rem;
    @media only screen and (max-width: 900px) {
      padding: 1rem;
    }
  }
  &__content {
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    @media only screen and (max-width: 1000px) {
      justify-content: center;
    }
  }
  &__image {
    opacity: 1;
    object-fit: cover;
    &--second {
      width: 85%;
      height: 85%;
      max-width: 440px;
      max-height: 600px;
      object-position: 40% bottom;
    }
    &--first {
      width: 100%;
      height: 95%;
      padding-top: 2rem;
      object-position: left bottom;
    }
  }
  &__text {
    padding: 3rem 0;
    h4 {
      @include subheadingStyling;
      text-align: left;
    }
    p {
      @include bodyTextStyling;
      margin: 1.5rem 0;
      text-align: left;
      opacity: 1;
    }
    button {
      background-color: white;
      border: 1px solid #1a1a1a;
      color: #1a1a1a;
      width: 180px;
      height: 46px;
      font: bold 8.25px 'Alright-Sans';
      letter-spacing: 1.65px;
      text-transform: uppercase;  
      margin-bottom: 1rem;
      margin-top: 1rem;
      @media only screen and (max-width: 400px) {
        padding: 10px 30px;
      }
    }
    button:hover {
      background-color: #1a1a1a;
      color: white;
    }
  }
}
